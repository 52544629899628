import environmentConfigMock from '../mock/mock-environment-config';

const mockEnvironmentConfig = false;

export function shouldShowStoresShortcuts(environmentConfig) {
  const isJWeb = environmentConfig?.isNative;
  const appStoresEnabled = environmentConfig?.flags?.smartProAppStores;
  const showAppStores = !isJWeb && appStoresEnabled;
  const showShortcuts = environmentConfig?.flags?.smartProShortcuts;
  return { showAppStores, showShortcuts };
}

export function getReleaseFeatureFlag(environmentConfig) {
  const releaseFeatureFlag = environmentConfig?.flags?.releaseVersionHpSmartPro || 'published';
  return releaseFeatureFlag;
}

export function getEnvironmentConfig(environmentConfig = null) {
  return mockEnvironmentConfig || environmentConfig === null
    ? environmentConfigMock
    : environmentConfig;
}
