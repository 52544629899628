import autoHeal from './auto-heal.json';
import bookScanning from './book-scanning.json';
import flattenPages from './flatten-pages.json';
import idCardScanning from './id-card-scanning.json';
import mobileFax from './mobile-fax.json';
import multiItemRecognition from './multi-item-recognition.json';
import printAnywhere from './print-anywhere.json';
import saveAsTextFiles from './save-as-text-files.json';
import smartDashboard from './smart-dashboard.json';
import smartFileNaming from './smart-file-naming.json';
import textExtract from './text-extract.json';

export default {
  autoHeal,
  bookScanning,
  flattenPages,
  idCardScanning,
  mobileFax,
  multiItemRecognition,
  saveAsTextFiles,
  smartFileNaming,
  textExtract,
  printAnywhere,
  smartDashboard,
};
