import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import { Content, Description, Header } from './styles';
import { Card, Title } from '../Card/styles';
import { redundantLocales } from '../../assets/locale';
import {
  GoogleAppStoreBadge,
  MacAppStoreBadge,
  MicrosoftAppStoreBadge,
  iOSAppStoreBadge,
} from '../../assets/locale/appStoreBadges';
import { STORES_URLS } from '../../utils/constants';

const AppStoreBadge = ({ image, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      src={image.url}
      alt={image.alt}
    />
  </a>
);

AppStoreBadge.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

const getLocaleRedundant = locale => propOr(locale, locale, redundantLocales);

const AppStores = () => {
  const { t } = useI18n();
  const {
    localization: { locale = null },
  } = useRootContext();

  const localeTreated = locale?.replace('-', '_');

  const CardContent = useMemo(
    () => (
      <>
        <Header>
          <Title data-testid="app-stores-card-header">
            {t('appStores.cardHeader')}
          </Title>
        </Header>
        <Description data-testid="app-stores-description">
          {t('appStores.cardDescription')}
        </Description>
        <Content>
          <AppStoreBadge
            image={{
              url: iOSAppStoreBadge[
                getLocaleRedundant(localeTreated)
              ]?.toString(),
              alt: 'Download the Smart App on the iOS App Store',
            }}
            url={STORES_URLS.iOS}
          />
          <AppStoreBadge
            image={{
              url: GoogleAppStoreBadge[
                getLocaleRedundant(localeTreated)
              ]?.toString(),
              alt: 'Download the Smart App on Google Play',
            }}
            url={STORES_URLS.Google}
          />
          <AppStoreBadge
            image={{
              url: MicrosoftAppStoreBadge[
                getLocaleRedundant(localeTreated)
              ]?.toString(),
              alt: 'Download the Smart App from Microsoft Store',
            }}
            url={STORES_URLS.Microsoft}
          />
          <AppStoreBadge
            image={{
              url: MacAppStoreBadge[
                getLocaleRedundant(localeTreated)
              ]?.toString(),
              alt: 'Download the Smart App on the Mac App Store',
            }}
            url={STORES_URLS.Mac}
          />
        </Content>
      </>
    ),
    [localeTreated, t],
  );

  return (
    <Card
      data-testid="app-stores"
      content={CardContent}
    />
  );
};

export default AppStores;
