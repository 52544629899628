import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web/build/player/lottie_light.min';
import {
  AnimationContainer,
  Content,
  Description,
  StyledMobileCarouselItem,
  Title,
} from './styles';

const MobileCarouselItem = props => {
  const { animation, description, title } = props;

  useEffect(() => {
    if (animation) {
      lottie.loadAnimation({
        path: animation.animationData,
        container: document.getElementById(animation.containerId),
        renderer: 'svg',
        loop: true,
        autoplay: true,
      });
    }
  }, [animation]);

  return (
    <StyledMobileCarouselItem>
      <AnimationContainer id={animation.containerId} />
      <Content>
        <Title className="body">{title}</Title>
        <Description className="caption">{description}</Description>
      </Content>
    </StyledMobileCarouselItem>
  );
};

MobileCarouselItem.propTypes = {
  animation: PropTypes.shape({
    animationData: PropTypes.objectOf(PropTypes.any).isRequired,
    containerId: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default MobileCarouselItem;
