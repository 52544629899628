import React from 'react';
import App from '../src/index';
import projectNames from '../src/configs/projectNames';
import subscriptionMock from '../src/mock/mock-subscription-status.json';
import { getEnvironmentConfig } from '../src/utils/environments';
/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  const environmentConfig = getEnvironmentConfig();
  const shellProps = window.Shell || {};

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <App
        shell={shellProps}
        {...props}
        environmentConfig={environmentConfig}
        subscriptionData={subscriptionMock}
      />
    </section>
  );
}
