import React from 'react';
import PropTypes from 'prop-types';
import {
  Content, Description, Image, StyledCardItem, Title,
} from './styles';

const CardItem = props => {
  const { description, image, title } = props;

  return (
    <StyledCardItem>
      <Image>
        <img src={image.url} alt={image.alt || 'icon'} />
      </Image>
      <Content>
        <Title className="body">{title}</Title>
        <Description className="caption">{description}</Description>
      </Content>
    </StyledCardItem>
  );
};

CardItem.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default CardItem;
