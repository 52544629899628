import bg from './Google_app_store_badge_bg.svg';
import cs from './Google_app_store_badge_cs.svg';
import da from './Google_app_store_badge_da.svg';
import de from './Google_app_store_badge_de.svg';
import el from './Google_app_store_badge_el.svg';
import en from './Google_app_store_badge_en.svg';
import es from './Google_app_store_badge_es.svg';
import et from './Google_app_store_badge_et.svg';
import fi from './Google_app_store_badge_fi.svg';
import fr from './Google_app_store_badge_fr.svg';
import frCa from './Google_app_store_badge_fr-ca.svg';
import hr from './Google_app_store_badge_hr.svg';
import hu from './Google_app_store_badge_hu.svg';
import it from './Google_app_store_badge_it.svg';
import ja from './Google_app_store_badge_ja.svg';
import ko from './Google_app_store_badge_ko.svg';
import lt from './Google_app_store_badge_lt.svg';
import lv from './Google_app_store_badge_lv.svg';
import no from './Google_app_store_badge_no.svg';
import nl from './Google_app_store_badge_nl.svg';
import pl from './Google_app_store_badge_pl.svg';
import ptBr from './Google_app_store_badge_pt-br.svg';
import pt from './Google_app_store_badge_pt.svg';
import ro from './Google_app_store_badge_ro.svg';
import ru from './Google_app_store_badge_ru.svg';
import sk from './Google_app_store_badge_sk.svg';
import sl from './Google_app_store_badge_sl.svg';
import sv from './Google_app_store_badge_sv.svg';
import tr from './Google_app_store_badge_tr.svg';
import zhCn from './Google_app_store_badge_zh-cn.svg';
import zhHk from './Google_app_store_badge_zh-hk.svg';
import zhTw from './Google_app_store_badge_zh-tw.svg';

const GoogleAppStoreImages = {
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  fi,
  fr,
  frCa,
  hr,
  hu,
  it,
  ja,
  ko,
  lt,
  lv,
  no,
  nl,
  pl,
  ptBr,
  pt,
  ro,
  ru,
  sk,
  sl,
  sv,
  tr,
  zhCn,
  zhHk,
  zhTw,
};

export default GoogleAppStoreImages;
