import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HpSmartProPage from '../pages/HpSmartPro';

const Routes = props => (
  <Switch>
    <Route path="/solutions/hp-smart-pro">
      <HpSmartProPage {...props} />
    </Route>
    <Route path="/">
      <HpSmartProPage {...props} />
    </Route>
  </Switch>
);

export default Routes;
