export const getFormattedDate = date => date.toLocaleDateString('en-US', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
});

export const getFormattedDateLong = date => date.toLocaleDateString('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

export const getFormattedTime = date => date.toLocaleTimeString('en-US');

export const formatDateTime = date => `${getFormattedDate(date)} | ${getFormattedTime(date)}`;

export const diffYearsDate = (startDate, endDate) => {
  const yearNew = endDate.getFullYear();
  const monthNew = endDate.getMonth();
  const dayNew = endDate.getDate();
  const yearOld = startDate.getFullYear();
  const monthOld = startDate.getMonth();
  const dayOld = startDate.getDate();
  let diffYear = yearNew - yearOld;
  if (monthOld > monthNew) {
    diffYear -= 1;
  } else if (monthOld === monthNew && dayOld > dayNew) {
    diffYear -= 1;
  }
  return diffYear;
};

export const diffMonthDate = (startDate, endDate) => {
  let months;
  months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  return months <= 0 ? 0 : months;
};
