import bannerNotebooks from './banner-notebooks.png';
import iconBookScanning from './icon-book-scanning.svg';
import iconAutoHeal from './icon-auto-heal.svg';
import iconFlattenPages from './icon-flatten-pages.svg';
import iconIDCardScanning from './icon-id-card-scanning.svg';
import iconMultiItemRecognition from './icon-multi-item-recognition.svg';
import iconPrintAnywhere from './print-anywhere.svg';
import iconPrinter from './icon-printer.svg';
import iconSaveAsTextFiles from './icon-save-as-text-files.svg';
import iconSmartDashboard from './smart-dashboard.svg';
import iconSmartFileNaming from './icon-smart-file-naming.svg';
import iconTextExtract from './icon-text-extract.svg';

const Images = {
  bannerNotebooks,
  iconAutoHeal,
  iconBookScanning,
  iconFlattenPages,
  iconIDCardScanning,
  iconMultiItemRecognition,
  iconPrintAnywhere,
  iconPrinter,
  iconSaveAsTextFiles,
  iconSmartDashboard,
  iconSmartFileNaming,
  iconTextExtract,
};

export default Images;
