import { path, propOr } from 'ramda';
import { diffYearsDate } from './date';
import { ENTITLEMENT_SMART_PRO, TIME_ZERO } from './constants';

export function findEntitlements(programInfos, serviceId) {
  return Object.entries(programInfos.programInfoMap)
    .map(([, programInfo]) => propOr([], 'entitlementList', programInfo))
    .flat()
    .filter(x => x.serviceId === serviceId);
}

export function findEnabledEntitlement(programInfo, serviceId) {
  const entitlements = findEntitlements(programInfo, serviceId);
  return entitlements.find(e => e.state === 'ENABLED');
}

export const getSmartProEntitlement = programInfos => {
  const smartProEntitlement = {
    lastUpdated: Date.now(),
    enabled: false,
    endTrial: null,
    startTrial: null,
    yearsTrialPeriod: null,
  };

  if (programInfos) {
    const entitlement = findEnabledEntitlement(
      programInfos,
      ENTITLEMENT_SMART_PRO,
    );

    if (entitlement) {
      smartProEntitlement.endTrial = new Date(
        path(['trialInfo', 'end'], entitlement) + TIME_ZERO,
      );
      smartProEntitlement.startTrial = new Date(
        path(['trialInfo', 'start'], entitlement) + TIME_ZERO,
      );
      smartProEntitlement.yearsTrialPeriod = diffYearsDate(
        smartProEntitlement.startTrial,
        smartProEntitlement.endTrial,
      );
      smartProEntitlement.enabled = true;
    }
  }

  return smartProEntitlement;
};

export default getSmartProEntitlement;
