import bg from './iOS_app_store_badge_BG.svg';
import cz from './iOS_app_store_badge_CZ.svg';
import dk from './iOS_app_store_badge_DK.svg';
import de from './iOS_app_store_badge_DE.svg';
import gr from './iOS_app_store_badge_GR.svg';
import usUk from './iOS_app_store_badge_US-UK.svg';
import es from './iOS_app_store_badge_ES.svg';
import ee from './iOS_app_store_badge_EE.svg';
import fi from './iOS_app_store_badge_FI.svg';
import fr from './iOS_app_store_badge_FR.svg';
import frCa from './iOS_app_store_badge_FRCA.svg';
import hu from './iOS_app_store_badge_HU.svg';
import it from './iOS_app_store_badge_IT.svg';
import jp from './iOS_app_store_badge_JP.svg';
import kr from './iOS_app_store_badge_KR.svg';
import lt from './iOS_app_store_badge_LT.svg';
import lv from './iOS_app_store_badge_LV.svg';
import no from './iOS_app_store_badge_NO.svg';
import nl from './iOS_app_store_badge_NL.svg';
import pl from './iOS_app_store_badge_PL.svg';
import ptBr from './iOS_app_store_badge_PTBR.svg';
import ptPt from './iOS_app_store_badge_PTPT.svg';
import ro from './iOS_app_store_badge_RO.svg';
import ru from './iOS_app_store_badge_RU.svg';
import sk from './iOS_app_store_badge_SK.svg';
import si from './iOS_app_store_badge_SI.svg';
import se from './iOS_app_store_badge_SE.svg';
import tr from './iOS_app_store_badge_TR.svg';
import cnSc from './iOS_app_store_badge_CNSC.svg';
import cnTc from './iOS_app_store_badge_CNTC.svg';

const iOSAppStoreImages = {
  bg,
  cz,
  dk,
  de,
  gr,
  usUk,
  es,
  ee,
  fi,
  fr,
  frCa,
  hu,
  it,
  jp,
  kr,
  lt,
  lv,
  no,
  nl,
  pl,
  ptBr,
  ptPt,
  ro,
  ru,
  sk,
  si,
  se,
  tr,
  cnSc,
  cnTc,
};

export default iOSAppStoreImages;
