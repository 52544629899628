import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import Animations from '../../animations';
import Card from '../Card';
import CardItem from '../CardItem';
import Images from '../../assets/images';
import MobileCarousel from '../Carousel';
import MobileCarouselItem from '../CarouselItem';
import { Asterisk } from './styles';

const showIDCardScanning = environmentConfig => environmentConfig
  && environmentConfig.flags
  && environmentConfig.flags.smartProIdCardScanning;

export const AdvancedScan = ({ releaseFeatureFlag, environmentConfig }) => {
  const { t } = useI18n();

  return (
    <Card
      title={t(
        `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.desktopHeader`,
      )}
      content={(
        <>
          <CardItem
            image={{ url: Images.iconMultiItemRecognition.toString() }}
            title={t('advancedScanFeatureCard.feature1.header')}
            description={t('advancedScanFeatureCard.feature1.description')}
          />
          <CardItem
            image={{ url: Images.iconFlattenPages.toString() }}
            title={t('advancedScanFeatureCard.feature5.header')}
            description={t('advancedScanFeatureCard.feature5.description')}
          />
          <CardItem
            image={{ url: Images.iconSaveAsTextFiles.toString() }}
            title={t('advancedScanFeatureCard.feature3.header')}
            description={t('advancedScanFeatureCard.feature3.description')}
          />
          <CardItem
            image={{ url: Images.iconAutoHeal.toString() }}
            title={t('advancedScanFeatureCard.feature6.header')}
            description={t('advancedScanFeatureCard.feature6.description')}
          />
          <CardItem
            image={{ url: Images.iconBookScanning.toString() }}
            title={t('advancedScanFeatureCard.feature4.header')}
            description={t('advancedScanFeatureCard.feature4.description')}
          />
          {showIDCardScanning(environmentConfig) && (
            <CardItem
              image={{ url: Images.iconIDCardScanning.toString() }}
              title={t('advancedScanFeatureCard.feature8.header')}
              description={t('advancedScanFeatureCard.feature8.description')}
            />
          )}
          {releaseFeatureFlag !== 'published' && (
            <Asterisk>
              {t(
                `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.desktopDescription`,
              )}
            </Asterisk>
          )}
        </>
      )}
    />
  );
};

AdvancedScan.defaultProps = {
  releaseFeatureFlag: 'published',
};

AdvancedScan.propTypes = {
  releaseFeatureFlag: PropTypes.string,
  environmentConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const AdvancedScanCarousel = ({
  releaseFeatureFlag,
  environmentConfig,
}) => {
  const { t } = useI18n();

  return (
    <>
      <MobileCarousel
        title={t(
          `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.mobileHeader`,
        )}
        description={
          releaseFeatureFlag !== 'published' ? (
            <Asterisk type="mobile">
              {t(
                `advancedScanFeatureCard.asterisk.releases.${releaseFeatureFlag}.mobileDescription`,
              )}
            </Asterisk>
          ) : null
        }
      >
        <MobileCarouselItem
          animation={{
            animationData: Animations.multiItemRecognition,
            containerId:
              'advanced-scan-multi-recognition-carousel-item-container',
          }}
          title={t('advancedScanFeatureCard.feature1.header')}
          description={t('advancedScanFeatureCard.feature1.description')}
        />
        <MobileCarouselItem
          animation={{
            animationData: Animations.saveAsTextFiles,
            containerId:
              'advanced-scan-save-as-text-files-carousel-item-container',
          }}
          title={t('advancedScanFeatureCard.feature5.header')}
          description={t('advancedScanFeatureCard.feature4.description')}
        />
        <MobileCarouselItem
          animation={{
            animationData: Animations.flattenPages,
            containerId: 'advanced-scan-flatten-pages-carousel-item-container',
          }}
          title={t('advancedScanFeatureCard.feature3.header')}
          description={t('advancedScanFeatureCard.feature3.description')}
        />
        <MobileCarouselItem
          animation={{
            animationData: Animations.autoHeal,
            containerId: 'advanced-scan-auto-heal-carousel-item-container',
          }}
          title={t('advancedScanFeatureCard.feature6.header')}
          description={t('advancedScanFeatureCard.feature6.description')}
        />
        <MobileCarouselItem
          animation={{
            animationData: Animations.bookScanning,
            containerId: 'advanced-scan-book-scanning-carousel-item-container',
          }}
          title={t('advancedScanFeatureCard.feature4.header')}
          description={t('advancedScanFeatureCard.feature4.description')}
        />
        {showIDCardScanning(environmentConfig) && (
          <MobileCarouselItem
            animation={{
              animationData: Animations.idCardScanning,
              containerId:
                'advanced-scan-id-cards-scanning-carousel-item-container',
            }}
            title={t('advancedScanFeatureCard.feature8.header')}
            description={t('advancedScanFeatureCard.feature8.description')}
          />
        )}
      </MobileCarousel>
    </>
  );
};

AdvancedScanCarousel.defaultProps = {
  releaseFeatureFlag: 'published',
};

AdvancedScanCarousel.propTypes = {
  releaseFeatureFlag: PropTypes.string,
  environmentConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};
