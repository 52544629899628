/// UCDE environment config
const environmentConfigMock = {
  country: 'us',
  language: 'en',
  flags: {
    accountDeletion: false,
    accountDeletionPage: true,
    avatarMenuWithOrgSelector: true,
    blueDashboard: true,
    blueDashboardCheckSharedPrinters: true,
    changePassword: true,
    comfeEnable: false,
    comfeEnableByQueryString: true,
    comfeEnabled: false,
    connectAnywhere: true,
    dashboardV2: true,
    deviceStatusSkipGen2: false,
    deviceSuppliesSkipGen2: true,
    forceDisplayHpSmartAdvance: true,
    forestFirst: true,
    hpPlusSecondOffer: true,
    hpPlusSecondOfferIiMonthsGBtoUk: true,
    hpPlusSecondOfferIiMonthsMocked: false,
    hpSmartAdvance: true,
    hpSmartPro: true,
    instantInkMenusPhase1: true,
    instantInkMenusPhase2: true,
    multipleBannerNotification: true,
    newAccountSharing: true,
    newEntitlement: true,
    notifications: true,
    printPlansRebranding: true,
    publishedLocales: [
      'bg_BG',
      'cs_CZ',
      'da_DK',
      'de_AT',
      'de_CH',
      'de_DE',
      'de_LI',
      'de_LU',
      'el_CY',
      'el_GR',
      'en_AE',
      'en_AF',
      'en_AG',
      'en_AI',
      'en_AL',
      'en_AU',
      'en_AW',
      'en_AZ',
      'en_BA',
      'en_BB',
      'en_BD',
      'en_BH',
      'en_BN',
      'en_BS',
      'en_BT',
      'en_BW',
      'en_BZ',
      'en_CA',
      'en_CM',
      'en_CR',
      'en_CY',
      'en_DZ',
      'en_EG',
      'en_ET',
      'en_GB',
      'en_GD',
      'en_GE',
      'en_GH',
      'en_HK',
      'en_ID',
      'en_IE',
      'en_IL',
      'en_IN',
      'en_IS',
      'en_JM',
      'en_JO',
      'en_JP',
      'en_KE',
      'en_KH',
      'en_KN',
      'en_KW',
      'en_KY',
      'en_LA',
      'en_LB',
      'en_LC',
      'en_LK',
      'en_LY',
      'en_MA',
      'en_ME',
      'en_MK',
      'en_MM',
      'en_MN',
      'en_MS',
      'en_MT',
      'en_MU',
      'en_MV',
      'en_MY',
      'en_NA',
      'en_NG',
      'en_NP',
      'en_NZ',
      'en_OM',
      'en_PH',
      'en_PK',
      'en_PR',
      'en_QA',
      'en_RS',
      'en_RW',
      'en_SA',
      'en_SG',
      'en_SX',
      'en_TC',
      'en_TH',
      'en_TL',
      'en_TN',
      'en_TT',
      'en_TW',
      'en_TZ',
      'en_UG',
      'en_US',
      'en_VC',
      'en_VG',
      'en_VI',
      'en_VN',
      'en_ZA',
      'en_ZM',
      'es_AR',
      'es_BO',
      'es_CL',
    ],
    releaseVersionHpSmartAdvance: 'published',
    removePrinter: true,
    services: true,
    showUsersAndInviteUsers: false,
    smartAdvAppStores: true,
    smartAdvIdCardScanning: true,
    smartAdvMobileProductivity: true,
    smartAdvShortcuts: true,
    smartAdvSolutionsCard: true,
    smartProIdCardScanning: true,
    smartProAppStores: true,
    smartSecurityDisabled: false,
    thisIsATestFlag: 'value1',
    transferPrinterConsumer: true,
    transferPrinterConsumerUsePieTroposUrl: false,
    usageCard: true,
    virtualAgentNewApiLanguages: [
      'de',
      'en',
      'es',
      'fr',
      'ja',
      'nl',
      'pt',
      'zh',
    ],
    wfhCallTypePassthrough: true,
    whatsAppSupport: true,
    isJWebAndroidApp: false,
    isJWebDesktopApp: undefined,
    isJWebiOSApp: false,
    isNative: false,
    stack: 'dev',
  },
};

export default environmentConfigMock;
