import React from 'react';
import { HyperlinkText, useI18n } from '@jarvis/react-portal-addons';
import { Title } from './styles';

const Help = () => {
  const { t } = useI18n();

  return (
    <div>
      <Title
        className="subtitle-regular"
        data-testid="help-link-title"
      >
        {t('help.subHeader')}
      </Title>
      <HyperlinkText url="https://support.hp.com/">
        {t('help.link')}
      </HyperlinkText>
    </div>
  );
};
export default Help;
