const crypto = require('crypto');

export const LD_ANONYMOUS_USER_KEY = '2f183a4e64493af3f377f745eda502363cd3e7ef6e4d266d444758de0a85fcc8-ANONYMOUS';

export const getLDClientSideID = () => {
  const host = window.location.hostname;

  return {
    'smb.local.portalshell.int.hp.com': '60e4a74fe99f68251606a031',
    'smb.dev.portalshell.int.hp.com': '60e4a74fe99f68251606a031',
    'smb.pie.portalshell.int.hp.com': '60e4a764b083b3245f3d8248',
    'smb.stage.portalshell.int.hp.com': '60e4a76fb083b3245f3d824b',
    'admin.hpsmart.com': '60e4a7785f58e025b8153b5b',
  }[host];
};

export const getUniqueUserKey = (locale = 'en-US') => {
  try {
    const idHasher = crypto.createHash('sha256');
    idHasher.update(locale);
    return idHasher.digest('hex');
  } catch (_) {
    return LD_ANONYMOUS_USER_KEY;
  }
};

export default {
  getUniqueUserKey,
};
