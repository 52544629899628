import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import {
  Background,
  ButtonContainer,
  Container,
  Description,
  Ellipse,
  EllipseContainer,
  GetSmartAppButton,
  Title,
} from './styles';

const AppStoresButton = () => {
  const { t } = useI18n();

  return (
    <ButtonContainer>
      <a
        href="https://123.hp.com/devices/setup-app"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GetSmartAppButton>{t('appStores.buttonText')}</GetSmartAppButton>
      </a>
    </ButtonContainer>
  );
};

const Banner = ({ showAppStores }) => {
  const { t } = useI18n();

  return (
    <Background>
      <Container>
        <EllipseContainer>
          <Ellipse>{t('topBanner.badge')}</Ellipse>
        </EllipseContainer>
        <Title className="title-small">{t('topBanner.header')}</Title>
        <Description className="body">{t('topBanner.subHeader')}</Description>
        {showAppStores && <AppStoresButton />}
      </Container>
    </Background>
  );
};

Banner.propTypes = {
  showAppStores: PropTypes.bool.isRequired,
};

export default Banner;
