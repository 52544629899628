import { useCallback, useEffect, useState } from 'react';
import {
  AccountMgtSvcClient,
  AccountMgtSvcClientV3,
  UserMgtSvcClientv3,
} from '@jarvis/web-stratus-client';

export const V2 = 'v2';
export const V3 = 'v3';

const useProgramInfosApiCall = ({
  authProvider,
  stack,
  version = V2,
  init = true,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchUserInfo = useCallback(async () => {
    const userMgtSvcClient = new UserMgtSvcClientv3(stack, authProvider);
    const response = await userMgtSvcClient.getCurrentActiveUser();
    return response.data;
  }, [authProvider, stack]);

  const fetchProgramInfos = useCallback(
    async (accountId, userId) => {
      const accountMgtSvcClient = version === V2
        ? new AccountMgtSvcClient(stack, authProvider)
        : new AccountMgtSvcClientV3(stack, authProvider);

      const response = await accountMgtSvcClient.getProgramInfos(
        accountId,
        userId,
      );
      return response.data;
    },
    [authProvider, stack, version],
  );

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        setError(null);
        const userInfo = await fetchUserInfo();
        const programInfos = await fetchProgramInfos(
          userInfo.tenantResourceId,
          userInfo.resourceId,
        );
        setData(programInfos);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (init) {
      fetchInfo();
    }
  }, [fetchProgramInfos, fetchUserInfo, init]);

  return {
    error,
    loading,
    data,
  };
};

export default useProgramInfosApiCall;
