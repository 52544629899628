import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as VeneerThemeProvider, useTheme } from '@veneer/theme';
import {
  GRANT_CHECK_TYPE,
  GRANT_STATES,
  RootProvider,
  useGrants,
} from '@jarvis/react-portal-addons';
import Routes from './routes';
import { mergeThemeWithEnvConfigInfo } from './theme-provider';
import resources from './assets/locale/index';
import useProgramInfosApiCall from './hooks/useProgramInfosApiCall';
import { getSmartProEntitlement } from './utils/entitlements';
import { ENTITLEMENT_SMART_PRO } from './utils/constants';

export const history = createBrowserHistory();

const SMART_PRO_GRANT = [{ grant: ENTITLEMENT_SMART_PRO }];
const SMART_PRO_GRANT_STATES = [GRANT_STATES.ENABLED];

const App = props => {
  const {
    environmentConfig = null,
    stack = null,
    subscriptionData = null,
    shell,
  } = props;

  const { grantsSupport } = useFlags();

  const localization = props.shell?.v1?.localization || {};
  const authProvider = props?.shell?.v1?.authProvider;
  const grants = props?.shell?.v1?.grants;

  const programInfosApiCall = useProgramInfosApiCall({
    authProvider,
    stack,
    init: !grantsSupport,
  });

  const { grantsCall } = useGrants({
    grantsInterface: grants,
    checkType: GRANT_CHECK_TYPE.ALL,
    states: SMART_PRO_GRANT_STATES,
    grants: SMART_PRO_GRANT,
    init: grantsSupport,
  });

  const programInfos = programInfosApiCall?.data;
  const subscriptionSmartProInfo = useMemo(() => (
    grantsSupport ? { enabled: grantsCall.data } : getSmartProEntitlement(programInfos)),
  [grantsCall.data, grantsSupport, programInfos]);

  const veneerTheme = useTheme();
  const theme = {
    ...mergeThemeWithEnvConfigInfo(environmentConfig),
    ...veneerTheme,
  };

  return (
    <RootProvider
      localization={localization}
      resources={resources}
      shell={shell}
      stack={stack}
    >
      <VeneerThemeProvider>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Routes
              {...props}
              subscriptionData={subscriptionData}
              subscriptionSmartProInfo={subscriptionSmartProInfo}
            />
          </Router>
        </ThemeProvider>
      </VeneerThemeProvider>
    </RootProvider>
  );
};

App.propTypes = {
  environmentConfig: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
  shell: PropTypes.objectOf(PropTypes.any),
  subscriptionData: PropTypes.objectOf(PropTypes.any),
};

App.defaultProps = {
  environmentConfig: null,
  shell: {},
  stack: 1,
  subscriptionData: null,
};

export default App;
