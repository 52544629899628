import { Card as VeneerCard } from '@veneer/core';
import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Card = styled(VeneerCard)`
  box-shadow: 0 12px 40px #6a6a6a1a;
  padding: 30px 32px;
  max-width: 870px;

  @media (max-width: ${props => props.theme.screens.dxxl}) {
    ${props => props.theme.host.isDesktopApp && 'max-width: 572px;'};
  }

  @media (max-width: ${props => props.theme.screens.xxl}) {
    max-width: 572px;
  }
`;

export const Header = styled.div`
  line-height: 28px;
  padding-bottom: 5px;
  margin-bottom: 13px;
`;

export const Title = styled.div`
  display: inline-block;
  font-size: 20px;
  width: 50%;
`;

export const CardLink = styled.div`
  color: ${primitives.color.hpBlue7};
  display: inline-block;
  text-align: right;
  width: 50%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -15px 0;

  > * {
    margin-bottom: 15px;
  }
`;
