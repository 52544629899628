import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, CardLink, ContentWrapper, Header, Title,
} from './styles';

const CardWraper = props => {
  const { content, link, title } = props;

  return (
    <Card
      content={(
        <>
          <Header>
            <Title>{title}</Title>
            {link && <CardLink href={link.href}>{link.text}</CardLink>}
          </Header>
          <ContentWrapper>{content}</ContentWrapper>
        </>
      )}
    />
  );
};

CardWraper.defaultProps = {
  link: null,
};

CardWraper.propTypes = {
  content: PropTypes.node.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
};

export default CardWraper;
