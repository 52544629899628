import bgBG from './bg_BG.json';
import csCZ from './cs_CZ.json';
import daDK from './da_DK.json';
import deDE from './de_DE.json';
import elGR from './el_GR.json';
import enCA from './en_CA.json';
import enGB from './en_GB.json';
import enUS from './en_US.json';
import esES from './es_ES.json';
import etEE from './et_EE.json';
import fiFI from './fi_FI.json';
import frCA from './fr_CA.json';
import frFR from './fr_FR.json';
import hrHR from './hr_HR.json';
import huHU from './hu_HU.json';
import itIT from './it_IT.json';
import jaJP from './ja_JP.json';
import koKR from './ko_KR.json';
import ltLT from './lt_LT.json';
import lvLV from './lv_LV.json';
import nbNO from './nb_NO.json';
import nlNL from './nl_NL.json';
import plPL from './pl_PL.json';
import ptBR from './pt_BR.json';
import ptPT from './pt_PT.json';
import roRO from './ro_RO.json';
import ruRU from './ru_RU.json';
import skSK from './sk_SK.json';
import slSI from './sl_SI.json';
import svSE from './sv_SE.json';
import trTR from './tr_TR.json';
import zhCN from './zh_CN.json';
import zhTW from './zh_TW.json';

export const Locales = {
  bg_BG: bgBG,
  cs_CZ: csCZ,
  da_DK: daDK,
  de_AT: deDE,
  de_CH: deDE,
  de_DE: deDE,
  de_LI: deDE,
  de_LU: deDE,
  el_CY: elGR,
  el_GR: elGR,
  en_AD: enUS,
  en_AE: enUS,
  en_AF: enUS,
  en_AG: enUS,
  en_AI: enUS,
  en_AL: enUS,
  en_AM: enUS,
  en_AU: enUS,
  en_AW: enUS,
  en_AZ: enUS,
  en_BA: enUS,
  en_BB: enUS,
  en_BD: enUS,
  en_BH: enUS,
  en_BN: enUS,
  en_BS: enUS,
  en_BT: enUS,
  en_BW: enUS,
  en_BZ: enUS,
  en_CA: enCA,
  en_CM: enUS,
  en_CR: enUS,
  en_CY: enUS,
  en_DZ: enUS,
  en_EG: enUS,
  en_ET: enUS,
  en_FJ: enUS,
  en_GB: enGB,
  en_GD: enUS,
  en_GE: enUS,
  en_GH: enUS,
  en_HK: enUS,
  en_ID: enUS,
  en_IE: enUS,
  en_IL: enUS,
  en_IN: enUS,
  en_IS: enUS,
  en_JM: enUS,
  en_JO: enUS,
  en_JP: enUS,
  en_KE: enUS,
  en_KH: enUS,
  en_KN: enUS,
  en_KW: enUS,
  en_KY: enUS,
  en_LA: enUS,
  en_LB: enUS,
  en_LC: enUS,
  en_LK: enUS,
  en_LY: enUS,
  en_MA: enUS,
  en_ME: enUS,
  en_MK: enUS,
  en_MM: enUS,
  en_MS: enUS,
  en_MT: enUS,
  en_MU: enUS,
  en_MV: enUS,
  en_MY: enUS,
  en_NA: enUS,
  en_NG: enUS,
  en_NP: enUS,
  en_NZ: enUS,
  en_OM: enUS,
  en_PG: enUS,
  en_PH: enUS,
  en_PK: enUS,
  en_PR: enUS,
  en_QA: enUS,
  en_RS: enUS,
  en_RW: enUS,
  en_SA: enUS,
  en_SG: enUS,
  en_SX: enUS,
  en_TC: enUS,
  en_TH: enUS,
  en_TJ: enUS,
  en_TL: enUS,
  en_TM: enUS,
  en_TN: enUS,
  en_TT: enUS,
  en_TW: enUS,
  en_TZ: enUS,
  en_UG: enUS,
  en_US: enUS,
  en_VC: enUS,
  en_VG: enUS,
  en_VI: enUS,
  en_VN: enUS,
  en_WS: enUS,
  en_ZA: enUS,
  en_ZM: enUS,
  es_AR: esES,
  es_BO: esES,
  es_CL: esES,
  es_CO: esES,
  es_CU: esES,
  es_DO: esES,
  es_EC: esES,
  es_ES: esES,
  es_GT: esES,
  es_HN: esES,
  es_MX: esES,
  es_NI: esES,
  es_PA: esES,
  es_PE: esES,
  es_PY: esES,
  es_SV: esES,
  es_UY: esES,
  es_VE: esES,
  et_EE: etEE,
  fi_FI: fiFI,
  fr_BE: frFR,
  fr_BJ: frFR,
  fr_BL: frFR,
  fr_CA: frCA,
  fr_CH: frFR,
  fr_CI: frFR,
  fr_FR: frFR,
  fr_GF: frFR,
  fr_GP: frFR,
  fr_HT: frFR,
  fr_LC: frFR,
  fr_LU: frFR,
  fr_MC: frFR,
  fr_MF: frFR,
  fr_MG: frFR,
  fr_MQ: frFR,
  fr_NC: frFR,
  fr_PF: frFR,
  fr_RE: frFR,
  fr_SN: frFR,
  fr_YT: frFR,
  hr_HR: hrHR,
  hu_HU: huHU,
  it_CH: itIT,
  it_IT: itIT,
  it_SM: itIT,
  it_VA: itIT,
  ja_JP: jaJP,
  ko_KR: koKR,
  lt_LT: ltLT,
  lv_LV: lvLV,
  nb_NO: nbNO,
  nl_BE: nlNL,
  nl_BQ: nlNL,
  nl_CW: nlNL,
  nl_NL: nlNL,
  pl_PL: plPL,
  pt_AO: ptPT,
  pt_BR: ptBR,
  pt_MO: ptPT,
  pt_MZ: ptPT,
  pt_PT: ptPT,
  ro_MD: roRO,
  ro_RO: roRO,
  ru_BY: ruRU,
  ru_KZ: ruRU,
  ru_RU: ruRU,
  ru_UA: ruRU,
  ru_UZ: ruRU,
  sk_SK: skSK,
  sl_SI: slSI,
  sv_FI: svSE,
  sv_SE: svSE,
  tr_TR: trTR,
  zh_CN: zhCN,
  zh_HK: zhTW,
  zh_MO: zhTW,
  zh_SG: zhCN,
  zh_TW: zhTW,
};

export const redundantLocales = {
  bg_BG: 'bg_BG',
  cs_CZ: 'cs_CZ',
  da_DK: 'da_DK',
  de_AT: 'de_DE',
  de_CH: 'de_DE',
  de_DE: 'de_DE',
  de_LI: 'de_DE',
  de_LU: 'de_DE',
  el_CY: 'el_GR',
  el_GR: 'el_GR',
  en_AD: 'en_US',
  en_AE: 'en_US',
  en_AF: 'en_US',
  en_AG: 'en_US',
  en_AI: 'en_US',
  en_AL: 'en_US',
  en_AM: 'en_US',
  en_AU: 'en_US',
  en_AW: 'en_US',
  en_AZ: 'en_US',
  en_BA: 'en_US',
  en_BB: 'en_US',
  en_BD: 'en_US',
  en_BH: 'en_US',
  en_BN: 'en_US',
  en_BS: 'en_US',
  en_BT: 'en_US',
  en_BW: 'en_US',
  en_BZ: 'en_US',
  en_CA: 'en_CA',
  en_CM: 'en_US',
  en_CR: 'en_US',
  en_CY: 'en_US',
  en_DZ: 'en_US',
  en_EG: 'en_US',
  en_ET: 'en_US',
  en_FJ: 'en_US',
  en_GB: 'en_GB',
  en_GD: 'en_US',
  en_GE: 'en_US',
  en_GH: 'en_US',
  en_HK: 'en_US',
  en_ID: 'en_US',
  en_IE: 'en_US',
  en_IL: 'en_US',
  en_IN: 'en_US',
  en_IS: 'en_US',
  en_JM: 'en_US',
  en_JO: 'en_US',
  en_JP: 'en_US',
  en_KE: 'en_US',
  en_KH: 'en_US',
  en_KN: 'en_US',
  en_KW: 'en_US',
  en_KY: 'en_US',
  en_LA: 'en_US',
  en_LB: 'en_US',
  en_LC: 'en_US',
  en_LK: 'en_US',
  en_LY: 'en_US',
  en_MA: 'en_US',
  en_ME: 'en_US',
  en_MK: 'en_US',
  en_MM: 'en_US',
  en_MS: 'en_US',
  en_MT: 'en_US',
  en_MU: 'en_US',
  en_MV: 'en_US',
  en_MY: 'en_US',
  en_NA: 'en_US',
  en_NG: 'en_US',
  en_NP: 'en_US',
  en_NZ: 'en_US',
  en_OM: 'en_US',
  en_PG: 'en_US',
  en_PH: 'en_US',
  en_PK: 'en_US',
  en_PR: 'en_US',
  en_QA: 'en_US',
  en_RS: 'en_US',
  en_RW: 'en_US',
  en_SA: 'en_US',
  en_SG: 'en_US',
  en_SX: 'en_US',
  en_TC: 'en_US',
  en_TH: 'en_US',
  en_TJ: 'en_US',
  en_TL: 'en_US',
  en_TM: 'en_US',
  en_TN: 'en_US',
  en_TT: 'en_US',
  en_TW: 'en_US',
  en_TZ: 'en_US',
  en_UG: 'en_US',
  en_US: 'en_US',
  en_VC: 'en_US',
  en_VG: 'en_US',
  en_VI: 'en_US',
  en_VN: 'en_US',
  en_WS: 'en_US',
  en_ZA: 'en_US',
  en_ZM: 'en_US',
  es_AR: 'es_ES',
  es_BO: 'es_ES',
  es_CL: 'es_ES',
  es_CO: 'es_ES',
  es_CU: 'es_ES',
  es_DO: 'es_ES',
  es_EC: 'es_ES',
  es_ES: 'es_ES',
  es_GT: 'es_ES',
  es_HN: 'es_ES',
  es_MX: 'es_ES',
  es_NI: 'es_ES',
  es_PA: 'es_ES',
  es_PE: 'es_ES',
  es_PY: 'es_ES',
  es_SV: 'es_ES',
  es_UY: 'es_ES',
  es_VE: 'es_ES',
  et_EE: 'et_EE',
  fi_FI: 'fi_FI',
  fr_BE: 'fr_FR',
  fr_BJ: 'fr_FR',
  fr_BL: 'fr_FR',
  fr_CA: 'fr_CA',
  fr_CH: 'fr_FR',
  fr_CI: 'fr_FR',
  fr_FR: 'fr_FR',
  fr_GF: 'fr_FR',
  fr_GP: 'fr_FR',
  fr_HT: 'fr_FR',
  fr_LC: 'fr_FR',
  fr_LU: 'fr_FR',
  fr_MC: 'fr_FR',
  fr_MF: 'fr_FR',
  fr_MG: 'fr_FR',
  fr_MQ: 'fr_FR',
  fr_NC: 'fr_FR',
  fr_PF: 'fr_FR',
  fr_RE: 'fr_FR',
  fr_SN: 'fr_FR',
  fr_YT: 'fr_FR',
  hr_HR: 'hr_HR',
  hu_HU: 'hu_HU',
  it_CH: 'it_IT',
  it_IT: 'it_IT',
  it_SM: 'it_IT',
  it_VA: 'it_IT',
  ja_JP: 'ja_JP',
  ko_KR: 'ko_KR',
  lt_LT: 'lt_LT',
  lv_LV: 'lv_LV',
  nb_NO: 'nb_NO',
  nl_BE: 'nl_NL',
  nl_BQ: 'nl_NL',
  nl_CW: 'nl_NL',
  nl_NL: 'nl_NL',
  pl_PL: 'pl_PL',
  pt_AO: 'pt_PT',
  pt_BR: 'pt_BR',
  pt_MO: 'pt_PT',
  pt_MZ: 'pt_PT',
  pt_PT: 'pt_PT',
  ro_MD: 'ro_RO',
  ro_RO: 'ro_RO',
  ru_BY: 'ru_RU',
  ru_KZ: 'ru_RU',
  ru_RU: 'ru_RU',
  ru_UA: 'ru_RU',
  ru_UZ: 'ru_RU',
  sk_SK: 'skSK',
  sl_SI: 'sl_SI',
  sv_FI: 'sv_SE',
  sv_SE: 'sv_SE',
  tr_TR: 'tr_TR',
  zh_CN: 'zh_CN',
  zh_HK: 'zh_TW',
  zh_MO: 'zh_TW',
  zh_SG: 'zh_CN',
  zh_TW: 'zh_TW',
};

export default Locales;
