import styled from 'styled-components';
import Banner from '../../components/Banner';

export const Content = styled.div`
  margin-left: ${props => (props.theme.host.isDesktopApp ? '260px' : '0')};
`;

export const Container = styled.div`
  display: flex;

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'flex-direction: column;'};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    flex-direction: column;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: -52px 33px 0;

  > * {
    margin-bottom: 18px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && 'margin: -64px auto 0;'};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    margin: -64px auto 0;
  }

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => (props.theme.host.isDesktopApp
    ? '> * { '
          + 'display: none; '
          + 'margin-bottom: 0; '
          + '} '
          + '> &:first-child { '
          + 'display: block; '
          + '}'
          + '}'
    : '')};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    > * {
      display: none;
      margin-bottom: 0;
    }

    > &:first-child {
      display: block;
    }
  }
`;

export const SubscriptionCardContainer = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && 'display: block;'};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    display: block;
  }
`;

export const MobileCarouselContainer = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'display: block;'};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    display: block;
  }
`;

export const RightContainer = styled.div`
  width: 255px;

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && 'flex-shrink: 1;'};
  }

  @media (max-width: ${props => props.theme.screens.lg}) {
    flex-shrink: 1;
  }

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && 'display: none;'};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    display: none;
  }
`;

export const HelpContainerTablet = styled.div`
  display: none;
  max-width: 268px;
  margin-left: 30px;

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && 'display: block;'};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    display: block;
  }

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'display: none;'};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    display: none;
  }
`;

export const HelpContainerMobile = styled.div`
  display: none;
  max-width: 268px;
  margin-left: 26px;

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'display: block;'};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    display: block;
  }
`;

export const SubscriptionContainer = styled.div`
  margin-top: 38px;
`;

export const HelpContainer = styled.div`
  margin-top: 30px;
`;

export const StyledBanner = styled(Banner)`
  margin-bottom: -52px;
`;
