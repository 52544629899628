import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LoaderWidget } from '@jarvis/react-portal-addons';
import { getLDClientSideID, getUniqueUserKey } from './utils/ldClient';
import App from './App';

const HPSmartProMFE = props => {
  const {
    shell,
  } = props;

  const localization = shell.v1?.localization;

  const [Container, setContainer] = useState(() => ({ children }) => children);
  const [fetchingLD, setFetchingLD] = useState(true);

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const LDProvider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(localization?.locale),
          },
        });
        setContainer(() => LDProvider);
      } catch (err) {
        console.error(err);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [localization?.locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  return (
    <Container>
      <App {...props} />
    </Container>
  );
};

HPSmartProMFE.defaultProps = {
  environmentConfig: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
  shell: PropTypes.objectOf(PropTypes.any),
  subscriptionData: PropTypes.objectOf(PropTypes.any),
};

HPSmartProMFE.propTypes = {
  environmentConfig: null,
  shell: {},
  stack: 1,
  subscriptionData: null,
};

export default HPSmartProMFE;
