import React from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import Animations from '../../animations';
import Card from '../Card';
import CardItem from '../CardItem';
import Carousel from '../Carousel';
import CarouselItem from '../CarouselItem';
import Images from '../../assets/images';

const showSmartDashboard = environmentConfig => environmentConfig
  && environmentConfig.flags
  && environmentConfig.flags.smartProMobileProductivity;

export const MobileProductivity = ({ solutions, environmentConfig }) => {
  const { t } = useI18n();

  return (
    <Card
      title={t('mobileProductivityCard.header')}
      content={(
        <>
          {solutions.printAnywhere && (
            <CardItem
              image={{ url: Images.iconPrintAnywhere.toString() }}
              title={t('mobileProductivityCard.feature2.header')}
              description={t('mobileProductivityCard.feature2.description')}
            />
          )}
          {solutions.smartDashboard
            && showSmartDashboard(environmentConfig) && (
              <CardItem
                image={{ url: Images.iconSmartDashboard.toString() }}
                title={t('mobileProductivityCard.feature3.header')}
                description={t('mobileProductivityCard.feature3.description')}
              />
          )}
        </>
      )}
    />
  );
};

MobileProductivity.propTypes = {
  solutions: PropTypes.objectOf(PropTypes.any).isRequired,
  environmentConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const MobileProductivityCarousel = ({
  solutions,
  environmentConfig,
}) => {
  const { t } = useI18n();

  return (
    <>
      <Carousel title={t('mobileProductivityCard.header')}>
        {solutions.printAnywhere && (
          <CarouselItem
            animation={{
              animationData: Animations.printAnywhere,
              containerId: 'printer-anywhere-corousel-item-container',
            }}
            title={t('mobileProductivityCard.feature2.header')}
            description={t('mobileProductivityCard.feature2.description')}
          />
        )}
        {solutions.smartDashboard && showSmartDashboard(environmentConfig) && (
          <CarouselItem
            animation={{
              animationData: Animations.smartDashboard,
              containerId: 'smart-dashboard-corousel-item-container',
            }}
            title={t('mobileProductivityCard.feature3.header')}
            description={t('mobileProductivityCard.feature3.description')}
          />
        )}
      </Carousel>
    </>
  );
};

MobileProductivityCarousel.propTypes = {
  solutions: PropTypes.objectOf(PropTypes.any).isRequired,
  environmentConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};
