import GoogleAppStoreImages from '../images/app-store/google';
import iOSAppStoreImages from '../images/app-store/ios';
import MacAppStoreImages from '../images/app-store/mac';
import MicrosoftAppStoreImages from '../images/app-store/microsoft';

export const GoogleAppStoreBadge = {
  bg_BG: GoogleAppStoreImages.bg,
  cs_CZ: GoogleAppStoreImages.cs,
  da_DK: GoogleAppStoreImages.da,
  de_AT: GoogleAppStoreImages.de,
  de_CH: GoogleAppStoreImages.de,
  de_DE: GoogleAppStoreImages.de,
  el_CY: GoogleAppStoreImages.el,
  el_GR: GoogleAppStoreImages.el,
  en_AU: GoogleAppStoreImages.en,
  en_CA: GoogleAppStoreImages.en,
  en_GB: GoogleAppStoreImages.en,
  en_IE: GoogleAppStoreImages.en,
  en_IL: GoogleAppStoreImages.en,
  en_MT: GoogleAppStoreImages.en,
  en_NZ: GoogleAppStoreImages.en,
  en_US: GoogleAppStoreImages.en,
  es_ES: GoogleAppStoreImages.es,
  et_EE: GoogleAppStoreImages.et,
  fi_FI: GoogleAppStoreImages.fi,
  fr_BE: GoogleAppStoreImages.fr,
  fr_CA: GoogleAppStoreImages.frCa,
  fr_CH: GoogleAppStoreImages.fr,
  fr_FR: GoogleAppStoreImages.fr,
  fr_LU: GoogleAppStoreImages.fr,
  hr_HR: GoogleAppStoreImages.hr,
  hu_HU: GoogleAppStoreImages.hu,
  it_CH: GoogleAppStoreImages.it,
  it_IT: GoogleAppStoreImages.it,
  ja_JP: GoogleAppStoreImages.ja,
  ko_KR: GoogleAppStoreImages.ko,
  lt_LT: GoogleAppStoreImages.lt,
  lv_LV: GoogleAppStoreImages.lv,
  nb_NO: GoogleAppStoreImages.no,
  nl_BE: GoogleAppStoreImages.nl,
  nl_NL: GoogleAppStoreImages.nl,
  pl_PL: GoogleAppStoreImages.pl,
  pt_BR: GoogleAppStoreImages.ptBr,
  pt_PT: GoogleAppStoreImages.pt,
  ro_MD: GoogleAppStoreImages.ro,
  ro_RO: GoogleAppStoreImages.ro,
  ru_RU: GoogleAppStoreImages.ru,
  sk_SK: GoogleAppStoreImages.sk,
  sl_SI: GoogleAppStoreImages.sl,
  sv_SE: GoogleAppStoreImages.sv,
  tr_TR: GoogleAppStoreImages.tr,
  zh_CN: GoogleAppStoreImages.zhCn,
  zh_HK: GoogleAppStoreImages.zhHk,
  zh_TW: GoogleAppStoreImages.zhTw,
};

export const iOSAppStoreBadge = {
  bg_BG: iOSAppStoreImages.bg,
  cs_CZ: iOSAppStoreImages.cz,
  da_DK: iOSAppStoreImages.dk,
  de_AT: iOSAppStoreImages.de,
  de_CH: iOSAppStoreImages.de,
  de_DE: iOSAppStoreImages.de,
  el_CY: iOSAppStoreImages.gr,
  el_GR: iOSAppStoreImages.gr,
  en_AU: iOSAppStoreImages.usUk,
  en_CA: iOSAppStoreImages.usUk,
  en_GB: iOSAppStoreImages.usUk,
  en_IE: iOSAppStoreImages.usUk,
  en_IL: iOSAppStoreImages.usUk,
  en_MT: iOSAppStoreImages.usUk,
  en_NZ: iOSAppStoreImages.usUk,
  en_US: iOSAppStoreImages.usUk,
  es_ES: iOSAppStoreImages.es,
  et_EE: iOSAppStoreImages.ee,
  fi_FI: iOSAppStoreImages.fi,
  fr_BE: iOSAppStoreImages.fr,
  fr_CA: iOSAppStoreImages.frCa,
  fr_CH: iOSAppStoreImages.fr,
  fr_FR: iOSAppStoreImages.fr,
  fr_LU: iOSAppStoreImages.fr,
  hr_HR: iOSAppStoreImages.usUk,
  hu_HU: iOSAppStoreImages.hu,
  it_CH: iOSAppStoreImages.it,
  it_IT: iOSAppStoreImages.it,
  ja_JP: iOSAppStoreImages.jp,
  ko_KR: iOSAppStoreImages.kr,
  lt_LT: iOSAppStoreImages.lt,
  lv_LV: iOSAppStoreImages.lv,
  nb_NO: iOSAppStoreImages.no,
  nl_BE: iOSAppStoreImages.nl,
  nl_NL: iOSAppStoreImages.nl,
  pl_PL: iOSAppStoreImages.pl,
  pt_BR: iOSAppStoreImages.ptBr,
  pt_PT: iOSAppStoreImages.ptPt,
  ro_MD: iOSAppStoreImages.ro,
  ro_RO: iOSAppStoreImages.ro,
  ru_RU: iOSAppStoreImages.ru,
  sk_SK: iOSAppStoreImages.sk,
  sl_SI: iOSAppStoreImages.si,
  sv_SE: iOSAppStoreImages.se,
  tr_TR: iOSAppStoreImages.tr,
  zh_CN: iOSAppStoreImages.cnSc,
  zh_HK: iOSAppStoreImages.cnTc,
  zh_TW: iOSAppStoreImages.cnTc,
};

export const MacAppStoreBadge = {
  bg_BG: MacAppStoreImages.bg,
  cs_CZ: MacAppStoreImages.cz,
  da_DK: MacAppStoreImages.dk,
  de_AT: MacAppStoreImages.de,
  de_CH: MacAppStoreImages.de,
  de_DE: MacAppStoreImages.de,
  el_CY: MacAppStoreImages.gr,
  el_GR: MacAppStoreImages.gr,
  en_AU: MacAppStoreImages.usUk,
  en_CA: MacAppStoreImages.usUk,
  en_GB: MacAppStoreImages.usUk,
  en_IE: MacAppStoreImages.usUk,
  en_IL: MacAppStoreImages.usUk,
  en_MT: MacAppStoreImages.usUk,
  en_NZ: MacAppStoreImages.usUk,
  en_US: MacAppStoreImages.usUk,
  es_ES: MacAppStoreImages.es,
  et_EE: MacAppStoreImages.ee,
  fi_FI: MacAppStoreImages.fi,
  fr_BE: MacAppStoreImages.fr,
  fr_CA: MacAppStoreImages.frCa,
  fr_CH: MacAppStoreImages.fr,
  fr_FR: MacAppStoreImages.fr,
  fr_LU: MacAppStoreImages.fr,
  hr_HR: MacAppStoreImages.usUk,
  hu_HU: MacAppStoreImages.hu,
  it_CH: MacAppStoreImages.it,
  it_IT: MacAppStoreImages.it,
  ja_JP: MacAppStoreImages.jp,
  ko_KR: MacAppStoreImages.kr,
  lt_LT: MacAppStoreImages.lt,
  lv_LV: MacAppStoreImages.lv,
  nb_NO: MacAppStoreImages.no,
  nl_BE: MacAppStoreImages.nl,
  nl_NL: MacAppStoreImages.nl,
  pl_PL: MacAppStoreImages.pl,
  pt_BR: MacAppStoreImages.ptBr,
  pt_PT: MacAppStoreImages.ptPt,
  ro_MD: MacAppStoreImages.ro,
  ro_RO: MacAppStoreImages.ro,
  ru_RU: MacAppStoreImages.ru,
  sk_SK: MacAppStoreImages.sk,
  sl_SI: MacAppStoreImages.si,
  sv_SE: MacAppStoreImages.se,
  tr_TR: MacAppStoreImages.tr,
  zh_CN: MacAppStoreImages.cnSc,
  zh_HK: MacAppStoreImages.cnTc,
  zh_TW: MacAppStoreImages.cnTc,
};

export const MicrosoftAppStoreBadge = {
  bg_BG: MicrosoftAppStoreImages.bulgarian,
  cs_CZ: MicrosoftAppStoreImages.czech,
  da_DK: MicrosoftAppStoreImages.danish,
  de_AT: MicrosoftAppStoreImages.german,
  de_CH: MicrosoftAppStoreImages.german,
  de_DE: MicrosoftAppStoreImages.german,
  el_CY: MicrosoftAppStoreImages.greek,
  el_GR: MicrosoftAppStoreImages.greek,
  en_AU: MicrosoftAppStoreImages.english,
  en_CA: MicrosoftAppStoreImages.english,
  en_GB: MicrosoftAppStoreImages.english,
  en_IE: MicrosoftAppStoreImages.english,
  en_IL: MicrosoftAppStoreImages.english,
  en_MT: MicrosoftAppStoreImages.english,
  en_NZ: MicrosoftAppStoreImages.english,
  en_US: MicrosoftAppStoreImages.english,
  es_ES: MicrosoftAppStoreImages.spanish,
  et_EE: MicrosoftAppStoreImages.estonian,
  fi_FI: MicrosoftAppStoreImages.finish,
  fr_BE: MicrosoftAppStoreImages.french,
  fr_CA: MicrosoftAppStoreImages.french,
  fr_CH: MicrosoftAppStoreImages.french,
  fr_FR: MicrosoftAppStoreImages.french,
  fr_LU: MicrosoftAppStoreImages.french,
  hr_HR: MicrosoftAppStoreImages.croatian,
  hu_HU: MicrosoftAppStoreImages.hungarian,
  it_CH: MicrosoftAppStoreImages.italian,
  it_IT: MicrosoftAppStoreImages.italian,
  ja_JP: MicrosoftAppStoreImages.japanese,
  ko_KR: MicrosoftAppStoreImages.korean,
  lt_LT: MicrosoftAppStoreImages.lithuanian,
  lv_LV: MicrosoftAppStoreImages.latvian,
  nb_NO: MicrosoftAppStoreImages.norwegian,
  nl_BE: MicrosoftAppStoreImages.dutch,
  nl_NL: MicrosoftAppStoreImages.dutch,
  pl_PL: MicrosoftAppStoreImages.polish,
  pt_BR: MicrosoftAppStoreImages.portugueseBrazilian,
  pt_PT: MicrosoftAppStoreImages.portuguesePortugal,
  ro_MD: MicrosoftAppStoreImages.romanian,
  ro_RO: MicrosoftAppStoreImages.romanian,
  ru_RU: MicrosoftAppStoreImages.russian,
  sk_SK: MicrosoftAppStoreImages.slovak,
  sl_SI: MicrosoftAppStoreImages.slovenian,
  sv_SE: MicrosoftAppStoreImages.swedish,
  tr_TR: MicrosoftAppStoreImages.turkish,
  zh_CN: MicrosoftAppStoreImages.chineseSimplified,
  zh_HK: MicrosoftAppStoreImages.chineseTraditional,
  zh_TW: MicrosoftAppStoreImages.chineseTraditional,
};
