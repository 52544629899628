import React from 'react';
import { useI18n } from '@jarvis/react-portal-addons';
import { path } from 'ramda';
import {
  AdvancedScan,
  AdvancedScanCarousel,
} from '../../components/AdvancedScan';
import {
  MobileProductivity,
  MobileProductivityCarousel,
} from '../../components/MobileProductivity';
import {
  ScanDestinations,
  ScanDestinationsCarousel,
} from '../../components/ScanDestinations';
import Subscription, { SubscriptionCard } from '../../components/Subscription';
import Help from '../../components/Help';
import {
  CardsContainer,
  Container,
  HelpContainer,
  HelpContainerMobile,
  HelpContainerTablet,
  MobileCarouselContainer,
  RightContainer,
  StyledBanner,
  SubscriptionCardContainer,
  SubscriptionContainer,
} from './styles';
import { Shortcuts, ShortcutsCarousel } from '../../components/Shortcuts';
import AppStores from '../../components/AppStores';
import NavigationArea from '../../components/NavigationArea';
import getBreadcrumbsItems from '../../utils/breadcrumbs';
import {
  getReleaseFeatureFlag,
  shouldShowStoresShortcuts,
} from '../../utils/environments';

const HpSmartPro = props => {
  const environmentConfig = path(['environmentConfig'], props);
  const subscriptionData = path(['subscriptionData'], props);
  const subscriptionSmartProInfo = path(['subscriptionSmartProInfo'], props);
  const { showAppStores, showShortcuts } = shouldShowStoresShortcuts(
    environmentConfig,
  );
  const { t } = useI18n();

  const printAnywhere = path(['solutions', 'printAnywhere'], subscriptionData);
  const smartDashboard = path(
    ['solutions', 'smartDashboard'],
    subscriptionData,
  );
  const showMobileProductivity = printAnywhere || smartDashboard;

  return (
    <>
      <NavigationArea
        data-test="hp-smart-pro-breadcrumbs"
        backLinkText="Home"
        breadcrumbsItems={getBreadcrumbsItems(t)}
      />
      <StyledBanner showAppStores={showAppStores} />
      <Container data-testid="hp-smart-pro-container">
        <CardsContainer>
          <SubscriptionCardContainer>
            <SubscriptionCard subscriptionStatus={subscriptionSmartProInfo} />
          </SubscriptionCardContainer>
          <AdvancedScan
            releaseFeatureFlag={getReleaseFeatureFlag(environmentConfig)}
            environmentConfig={environmentConfig}
          />
          <ScanDestinations
            solutions={subscriptionData.solutions}
            environmentConfig={environmentConfig}
          />
          {showMobileProductivity && (
            <MobileProductivity
              solutions={subscriptionData.solutions}
              environmentConfig={environmentConfig}
            />
          )}
          {showShortcuts && <Shortcuts />}
          {showAppStores && <AppStores />}
          <HelpContainerTablet>
            <Help />
          </HelpContainerTablet>
        </CardsContainer>

        <MobileCarouselContainer>
          <AdvancedScanCarousel
            releaseFeatureFlag={getReleaseFeatureFlag(environmentConfig)}
            environmentConfig={environmentConfig}
          />
          {showShortcuts && <ShortcutsCarousel />}
          {showMobileProductivity && (
            <MobileProductivityCarousel
              solutions={subscriptionData.solutions}
              environmentConfig={environmentConfig}
            />
          )}
          <ScanDestinationsCarousel
            solutions={subscriptionData.solutions}
            environmentConfig={environmentConfig}
          />
          {showAppStores && <AppStores />}
        </MobileCarouselContainer>

        <RightContainer>
          <SubscriptionContainer>
            <Subscription subscriptionStatus={subscriptionSmartProInfo} />
          </SubscriptionContainer>
          <HelpContainer>
            <Help />
          </HelpContainer>
        </RightContainer>
        <HelpContainerMobile>
          <Help />
        </HelpContainerMobile>
      </Container>
    </>
  );
};

export default HpSmartPro;
