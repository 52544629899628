import PropTypes from 'prop-types';
import React from 'react';
import {
  Carousel, ContentWrapper, Header, HeaderLink, Title,
} from './styles';

const MobileCarousel = props => {
  const {
    children, hideControls, link, title, description,
  } = props;

  return (
    <>
      <Header>
        <Title className="subtitle-regular">{title}</Title>
        {link && <HeaderLink href={link.href}>{link.text}</HeaderLink>}
      </Header>
      {description}
      <ContentWrapper>
        <Carousel
          cellSpacing={18}
          dragging
          slideWidth="288px"
          speed={1000}
          swiping
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          withoutControls={hideControls}
        >
          {children}
        </Carousel>
      </ContentWrapper>
    </>
  );
};

MobileCarousel.defaultProps = {
  description: null,
  hideControls: false,
  link: null,
};

MobileCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.objectOf(PropTypes.any),
  hideControls: PropTypes.bool,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
};

export default MobileCarousel;
