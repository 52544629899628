import styled from 'styled-components';
import { Button } from '@veneer/core';
import primitives from '@veneer/primitives';
import Images from '../../assets/images';

export const Background = styled.div`
  background-color: ${primitives.color.gray9};
  background-image: url('${Images.bannerNotebooks}');
  background-position: 986px;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 310px;

  @media (max-width: ${props => props.theme.screens.dxxl}) {
    ${props => props.theme.host.isDesktopApp && {
    backgroundPosition: '687px',
    minHeight: '340px',
  }};
  }

  @media (max-width: ${props => props.theme.screens.xxl}) {
    background-position: 687px;
    min-height: 340px;
  }

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && {
    backgroundPosition: 'right 5% top',
    backgroundSize: '217px 100%',
    minHeight: '260px',
  }};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    background-position: right 5% top;
    background-size: 217px 100%;
    min-height: 260px;
  }

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && {
    backgroundImage: 'none',
    backgroundPosition: 'unset',
    backgroundRepeat: 'unset',
    backgroundSize: 'unset',
    minHeight: '263px',
  }};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    background-image: none;
    background-position: unset;
    background-repeat: unset;
    background-size: unset;
    min-height: 263px;
  }

  @media (max-width: ${props => props.theme.screens.dxsm}) {
    ${props => props.theme.host.isDesktopApp && {
    minHeight: '263px',
    margin: '0 auto',
  }};
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    min-height: 263px;
    margin: 0 auto;
  }
`;

export const GetSmartAppButton = styled(Button)`
  && {
    background-color: ${primitives.color.gray9};
  }

  &&:hover {
    background-color: #565656;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 90px;

  @media (max-width: ${props => props.theme.screens.xsm}) {
    padding-top: 20px;
    text-align: center;
  }
`;

export const Container = styled.div`
  margin: 0 33px;
  padding: 0 33px;

  a {
    text-decoration: none;
  }

  a span {
    color: ${primitives.color.white};
  }

  @media (max-width: ${props => props.theme.screens.dlg}) {
    ${props => props.theme.host.isDesktopApp && 'width: 572px;'};
  }

  @media (max-width: ${props => props.theme.screens.lg}) {
    width: 572px;
  }

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && {
    margin: '0 auto',
    width: '592px',
  }};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    margin: 0 auto;
    width: 592px;
  }

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'width: 400px;'};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    width: 400px;
  }

  @media (max-width: ${props => props.theme.screens.dxsm}) {
    ${props => props.theme.host.isDesktopApp && 'width: 280px;'};
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    width: 280px;
  }
`;

export const EllipseContainer = styled.div`
  padding: 48px 0 20px;

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && 'padding: 32px 0 10px;'}
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    padding: 32px 0 10px;
  }
`;

export const Ellipse = styled.div`
  border-radius: 19px;
  background-color: ${primitives.color.gray3};
  font-size: ${primitives.typography.size2};
  height: 28px;
  line-height: 28px;
  text-align: center;
  width: 57px;

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'margin: 0 auto;'}
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  color: ${primitives.color.white};

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'text-align: center;'};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    text-align: center;
  }
`;

export const Description = styled.div`
  color: ${primitives.color.white};

  @media (max-width: ${props => props.theme.screens.dxxl}) {
    ${props => props.theme.host.isDesktopApp && 'width: 495px;'};
  }

  @media (max-width: ${props => props.theme.screens.xxl}) {
    width: 495px;
  }

  @media (max-width: ${props => props.theme.screens.dlg}) {
    ${props => props.theme.host.isDesktopApp && 'width: 517px;'};
  }

  @media (max-width: ${props => props.theme.screens.lg}) {
    width: 517px;
  }

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && {
    width: '400px',
  }};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    width: 400px;
  }

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && {
    textAlign: 'center',
  }};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    text-align: center;
  }

  @media (max-width: ${props => props.theme.screens.dxsm}) {
    ${props => props.theme.host.isDesktopApp && 'width: 280px;'};
  }

  @media (max-width: ${props => props.theme.screens.xsm}) {
    width: 280px;
  }
`;
