import styled from 'styled-components';
import {
  IconChevronLeft,
  Breadcrumbs as VeneerBreadcrumbs,
} from '@veneer/core';
import primitives from '@veneer/primitives';
import { rgba } from 'polished';

export const BackLinkContainer = styled.nav`
  align-items: center;
  color: ${primitives.color.white};
  display: inline-flex;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size1};
  margin-left: 20px;
  transition: background-color 100ms ease-out, color 100ms linear;
`;

export const BackLinkIcon = styled(IconChevronLeft)``;

export const BackLink = styled.span`
  border-radius: ${primitives.layout.cornerRadius1}px;
  cursor: pointer;
  padding: 4px 8px;

  &:hover {
    background-color: ${rgba(primitives.color.white, 0.05)};
  }

  color: ${primitives.color.gray4} !important;
`;

export const Breadcrumbs = styled(VeneerBreadcrumbs)`
  font-size: ${primitives.typography.size1};
  li > span {
    color: ${primitives.color.gray4} !important;
    &:last-child {
      color: ${primitives.color.white} !important;
    }
    &:hover {
      color: ${primitives.color.white} !important;
    }
  }
  li > a {
    color: ${primitives.color.gray4} !important;
    &:last-child {
      color: ${primitives.color.white} !important;
    }
    &:hover {
      color: ${primitives.color.white} !important;
    }
  }
`;

export const NavigationArea = styled.div`
  align-items: center;
  display: flex;
  height: 42px;
  background-color: ${primitives.color.gray9};
  color: ${primitives.color.white} !important;
`;
