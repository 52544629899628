export const STORES_URLS = {
  iOS: 'https://apps.apple.com/app/hp-smart/id469284907',
  Google: 'https://play.google.com/store/apps/details?id=com.hp.printercontrol',
  Microsoft: 'https://www.microsoft.com/p/hp-smart/9wzdncrfhwlh?rtc=1',
  Mac: 'https://apps.apple.com/app/hp-smart-for-desktop/id1474276998',
};

export const ENTITLEMENT_SMART_PRO = 'ws-hp.com/smart-pro';

export const TIME_ZERO = 'T00:00';
