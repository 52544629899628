import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const StyledCardItem = styled.div`
  display: flex;
  margin: 15px 0;
  max-width: 290px;
  width: 290px;

  @media (max-width: ${props => props.theme.screens.dxxl}) {
    ${props => props.theme.host.isDesktopApp && 'width: 286px'};
  }

  @media (max-width: ${props => props.theme.screens.xxl}) {
    width: 286px;
  }
`;

export const Image = styled.div`
  width: 57px;
`;

export const Content = styled.div`
  display: inline-block;
  width: 213px;
`;

export const Title = styled.div`
  color: ${primitives.color.gray12};
`;

export const Description = styled.div`
  color: ${primitives.color.gray9};
`;
