import NukaCarousel from 'nuka-carousel';
import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Carousel = styled(NukaCarousel)`
  .slider-control-bottomcenter {
    position: relative !important;
    display: flex;
    justify-content: center;

    > ul {
      top: 0 !important;
    }
  }

  .paging-item {
    button {
      fill: ${primitives.color.gray3} !important;

      &:focus {
        outline: none !important;
      }

      .paging-dot {
        height: 8px;
        width: 8px;

        > circle {
          cx: 4px;
          cy: 4px;
          r: 4px;
        }
      }
    }

    &.active {
      button {
        fill: ${primitives.color.hpBlue7} !important;
      }

      .paging-dot {
        height: 10px;
        width: 10px;

        > circle {
          cx: 5px;
          cy: 5px;
          r: 5px;
        }
      }
    }
  }
`;

export const Header = styled.div`
  line-height: 28px;
  padding-bottom: 5px;
  margin: 45px 30px 0 39px;
`;

export const Title = styled.div`
  display: inline-block;
  width: 50%;

  @media (max-width: ${props => props.theme.screens.dsm}) {
    ${props => props.theme.host.isDesktopApp && 'color: #4E4E4E;'};
  }

  @media (max-width: ${props => props.theme.screens.sm}) {
    color: #4e4e4e;
  }
`;

export const HeaderLink = styled.div`
  color: ${primitives.color.hpBlue6};
  display: inline-block;
  text-align: right;
  width: 50%;
`;

export const ContentWrapper = styled.div`
  margin: 0 9px;
`;
