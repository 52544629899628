import bulgarian from './Microsoft_app_store_badge_Bulgarian.svg';
import czech from './Microsoft_app_store_badge_Czech.svg';
import danish from './Microsoft_app_store_badge_Danish.svg';
import german from './Microsoft_app_store_badge_German.svg';
import greek from './Microsoft_app_store_badge_Greek.svg';
import english from './Microsoft_app_store_badge_English.svg';
import spanish from './Microsoft_app_store_badge_Spanish.svg';
import estonian from './Microsoft_app_store_badge_Estonian.svg';
import finish from './Microsoft_app_store_badge_Finnish.svg';
import french from './Microsoft_app_store_badge_French.svg';
import croatian from './Microsoft_app_store_badge_Croatian.svg';
import hungarian from './Microsoft_app_store_badge_Hungarian.svg';
import italian from './Microsoft_app_store_badge_Italian.svg';
import japanese from './Microsoft_app_store_badge_Japanese.svg';
import korean from './Microsoft_app_store_badge_Korean.svg';
import lithuanian from './Microsoft_app_store_badge_Lithuanian.svg';
import latvian from './Microsoft_app_store_badge_Latvian.svg';
import norwegian from './Microsoft_app_store_badge_Norwegian.svg';
import dutch from './Microsoft_app_store_badge_Dutch.svg';
import polish from './Microsoft_app_store_badge_Polish.svg';
import portugueseBrazilian from './Microsoft_app_store_badge_Portuguese-Brazilian.svg';
import portuguesePortugal from './Microsoft_app_store_badge_Portuguese-Portugal.svg';
import romanian from './Microsoft_app_store_badge_Romanian.svg';
import russian from './Microsoft_app_store_badge_Russian.svg';
import slovak from './Microsoft_app_store_badge_Slovak.svg';
import slovenian from './Microsoft_app_store_badge_Slovenian.svg';
import swedish from './Microsoft_app_store_badge_Swedish.svg';
import turkish from './Microsoft_app_store_badge_Turkish.svg';
import chineseSimplified from './Microsoft_app_store_badge_Chinese_Simplified.svg';
import chineseTraditional from './Microsoft_app_store_badge_Chinese-Traditional.svg';

const MicrosoftAppStoreImages = {
  bulgarian,
  czech,
  danish,
  german,
  greek,
  english,
  spanish,
  estonian,
  finish,
  french,
  croatian,
  hungarian,
  italian,
  japanese,
  korean,
  lithuanian,
  latvian,
  norwegian,
  dutch,
  polish,
  portugueseBrazilian,
  portuguesePortugal,
  romanian,
  russian,
  slovak,
  slovenian,
  swedish,
  turkish,
  chineseSimplified,
  chineseTraditional,
};

export default MicrosoftAppStoreImages;
