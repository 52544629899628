import styled from 'styled-components';
import primitives from '@veneer/primitives';

export const Title = styled.div`
  color: #464d50;
  font-family: ${primitives.typography.family0};
  border-bottom: 1px solid ${primitives.color.gray2};
  margin-bottom: 15px;
  padding-bottom: 8px;
  font-size: ${primitives.typography.size4};
  font-weight: 400;

  @media (max-width: ${props => props.theme.screens.dmd}) {
    ${props => props.theme.host.isDesktopApp && 'margin-top: 0;'};
  }

  @media (max-width: ${props => props.theme.screens.md}) {
    margin-top: 0;
  }
`;

export default {
  Title,
};
