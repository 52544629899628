import React from 'react';
import { useI18n } from '@jarvis/react-portal-addons';
import Animations from '../../animations';
import Card from '../Card';
import CardItem from '../CardItem';
import Carousel from '../Carousel';
import CarouselItem from '../CarouselItem';
import Images from '../../assets/images';

export const ScanDestinations = () => {
  const { t } = useI18n();

  return (
    <Card
      title={t('ScanDestinationsCard.header')}
      content={(
        <>
          <CardItem
            image={{ url: Images.iconSaveAsTextFiles.toString() }}
            title={t('ScanDestinationsCard.feature1.header')}
            description={t('ScanDestinationsCard.feature1.description')}
          />
          <CardItem
            image={{ url: Images.iconSaveAsTextFiles.toString() }}
            title={t('ScanDestinationsCard.feature2.header')}
            description={t('ScanDestinationsCard.feature2.description')}
          />
        </>
      )}
    />
  );
};

export const ScanDestinationsCarousel = () => {
  const { t } = useI18n();

  return (
    <>
      <Carousel title={t('ScanDestinationsCard.header')}>
        <CarouselItem
          animation={{
            animationData: Animations.saveAsTextFiles,
            containerId:
              'scandestinations-save-as-text-files-corousel-item-container',
          }}
          title={t('ScanDestinationsCard.feature1.header')}
          description={t('ScanDestinationsCard.feature1.description')}
        />
        <CarouselItem
          animation={{
            animationData: Animations.smartFileNaming,
            containerId:
              'scandestinations-smart-file-naming-corousel-item-container',
          }}
          title={t('ScanDestinationsCard.feature2.header')}
          description={t('ScanDestinationsCard.feature2.description')}
        />
      </Carousel>
    </>
  );
};
